/* latin-ext */
@font-face {
  font-family: 'Work_Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Work Sans Light'), local('WorkSans-Light'), url(https://fonts.gstatic.com/s/worksans/v4/QGYpz_wNahGAdqQ43Rh314L8lthN2fk.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Work_Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Work Sans Light'), local('WorkSans-Light'), url(https://fonts.gstatic.com/s/worksans/v4/QGYpz_wNahGAdqQ43Rh314L8mNhN.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: Work_Sans bold;
    src: url(fonts/Work_Sans/WorkSans-Medium.ttf);
}

:root {
  --brand-color: #e30916;
}

body { margin:0; padding:0; font-family:Work_Sans,Tahoma,verdana,Arail,Helvetica,sans-serif;background-color:#f5f5f5;font-size:17px;min-width:320px}
a:active{color:black!important}
a { color:#000; }
a:hover{ color:grey; }
a.anchor, .cuerpo a { color: #cd0000;}
.cuerpo h3 { font-weight: bold;}

.error, .important { color:red; } 

/* para form  suscrip*/
.datos .suscrip form { padding: 10px; }
    

/*---btn---*/
button {background-color:grey }
.lateral input[type=submit], .btn { font-size:15px;border:0px;font-weight: bold; }
.lateral input[type=submit] {
  padding: .375rem .75rem;
  border-radius: .25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  background-color: red;
  color: #fff;    line-height: 1.5;
  border-radius: .25rem;
}
.btn a,.btn:hover a{color:#fff}
.btn-danger{background-color:red}
.btn-primary{background-color:red}
.btn-primary:hover {
    color: #fff;
    background-color: rgb(211, 24, 24);
    border-color: red;
  }
.btn-light{ background-color: rgb(196, 195, 195);}
.btn-light:hover{ color: black!important;}
.btn-secondary{ background-color: rgb(170, 169, 169);}

#search .btn {
  background-color: #e60005;
  color: #fff;
}

.alert{border:0px;}
.alert-warning {background-color: #ffc107;color:black;}

.text-black{color:#000}
.big-text{font-size:24px;line-height:1em;}

.bg-suscriptor{background-color:#e4e4e4;color:#000}
.bg-lector{background-color:#ccc;color:#000}
.bg-danger{color:#fff}

  .btn:hover a,.fa,.subtitulo,a,a:hover,nav a,nav a:link{text-decoration:none}

  /*---card---*/
  .card {margin-bottom:10px;}
  .card-body .fecha{font-size:13px}
  .card-text, .extracto { font-size: 17px;line-height: 17px; }
  .card img{max-width:100%;height:auto;}
  .card figure.image>figcaption { border-top :1px solid #ccc; margin-top: 1em; font-size: .8em; padding: 1em; }

  .card .detalles,.detalles a:hover{background-color:#e60005;color:#fff}
  .card .detalles{padding:6px!important}
  .card-img-top {position:relative;}


.card>a.media { position: relative; }
.card>a.media::before {
        pointer-events: none;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        text-align: center;
        color: white;
        font-size: 175%;
        background: rgba(0, 0, 0, 0.45);
        padding: .9rem .7rem;
        border-radius: 5px;
    }
    .card>a.media.video::before {
        content: '\f04b';
    }

  .ocio_club .card,.lateral #cesta .contenido .card {
    border:0px;
  }

  .subtitulo{font-size:18px;color:#e60005;font-weight:700;line-height: 1em;margin: 5px 0px 5px 0px;}

  /*bootstrap radiobuttons colors*/
  .custom-control-label::before{ background-color: lightgrey; }
  .custom-control-input:active ~ .custom-control-label::before{ background-color: grey!important; }
  .custom-radio .custom-control-input:checked ~ .custom-control-label::before , .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before{ background-color: black!important; }
  .custom-control-input:checked ~ .custom-control-label::before{ background-color: black!important; }

  .login_link .user_box{ white-space: nowrap; overflow: hidden; text-overflow: ellipsis;} 
  .user_data { text-decoration: underline;}



  @media (max-width:767px){
     .login_link .user_box{ max-width: 100px; }
  }

  .remarkBox { width:auto;background: #E8E8E8; margin: 10px 0px; padding: 10px; border: 1px solid gray;}

  .remarkBox .text-danger {
    font-size: .8em;
  }

  .colIlustracion{
    margin-top:16px;
    margin-bottom:16px;
    text-align: center;
  }
  
  .colIlustracion img{
    max-width: 100%;
    height: auto;
    width: auto;
    max-height: 200px;
    
  }

  /*--publicidad banners--*/
  .publicidad_banner { margin-bottom: 10px; }
  .publicidad_banner .publicidad,.seccion .publicidad{/*height:250px;*/border:0px solid #000;text-align:center}
  /* img banner se reescala*/
  .publicidad_banner .publicidad{width:100%;height: auto;}

  /*img fluid*/
  .publicidad img {
    max-width: 100%;
    height: auto;
  }
  
  /*FAQ*/
  div.faq div.pregunta a { font-weight: bold; color: #706F6E; }
  div.faq div.pregunta.active a { color: red; }
  div.faq div.pregunta a:hover { color:#EE6B73 }
  div.faq div.respuesta { border-top: 1px solid #706F6E; border-bottom: 1px solid #706F6E; margin: 5px 0px; padding: 5px 0px; }
  div.faq ol { margin: 0px; padding-left: 20px ; }
  div.faq ol li{list-style-type: decimal;}
  div.faq ol li ul li{list-style-type: inherit;margin-left: 20px ;}

  li .pregunta{ margin: 5px; }

  #navbarNavDropdown ul,nav a:link{text-align:center}

  /* Bloques */
  div.bloque div.contenedor {padding-right: 10px}
  #bloque_1  img{ width: 50%; padding: 1px; }

  /* Contacto */
  form#fContacto label { font-weight: bold; margin-bottom: 0px!important;}

/*--- navbar ---*/
.jumbo { 
    background: var(--brand-color);
    padding:1rem;
}
.jumbo h1.logo>a {
    display:block;
    background-image:url(../images/logo_club_cabecera_white.png);
    background-repeat:no-repeat;
    background-size:contain;
    width:300px;
    height:102px;
    text-indent:100%;
    overflow: hidden;
    white-space:nowrap;
    text-align: center;
    margin: 0 auto;
}

main.legal .section-title h1 {
  font-size: 22px;
}
main.legal .post-text a {
    color: #cd0000;
}

  @media (max-width:768px){
    .follow-text{display:none}
    .jumbo{background-size:cover}
    .jumbo h1.logo>a{width:177px;height:70px}
  }

  nav #reloj{font-size: 12px;}
  @media(max-width:450px){
    #weather_text .nombre{
        font-size: 13px;
    }
  }
  nav a:focus{color:white;}
    
  @media (max-width:991px){
    #reloj{color:#fff;font-weight:700; font-size: 15px;line-height: 35px;}
    #search .btn {
       background: #000;
    }
  }

  @media (max-width:575px){
    #reloj{line-height: 15px;font-size: 12px;}
  }


  #reloj>span{color:red}


  #navbarNavDropdown ul>li.active {background:#bc9b4f; color: #000;}
  #navbarNavDropdown ul li:hover{color:#fff;background-color:#bc9b4f;}
  #navbarNavDropdown ul>li.active>a {color: #000; font-weight: bold; }

  nav{width:100%;z-index:1;background-color:#e60005; border-bottom: 10px solid #000;}

  .navbar-toggler{background-color:#d3d3d3}
  #navbarNavDropdown ul>li{background-color:#e60005}
  #navbarNavDropdown ul{width:100%;display:flex;justify-content:center;list-style-type:none;margin:0}
  #navbarNavDropdown ul li{width:auto;text-align:center;padding:10px 6px;}
  a:active,nav a,nav a:hover,nav a:link{color:#fff}
  nav a{display:inline-block;font-size:17px;padding-left:8px;padding-right:8px;line-height:35px}

  @media (max-width:991px){
    #navbarNavDropdown ul li{border:1px solid #000;border-radius:3px;margin:1px 15px}
    #navbarNavDropdown ul li a{display:block}
    nav a:link{color:#fff;text-decoration:none;display:inline;border-radius:3px;text-align:center}
    nav a{display:inline-block;line-height:35px}
    #navbarNavDropdown ul li:hover{color:#fff;background-color:#333}
  }
  #navbarNavDropdown ul li a{display:block}

  nav a:link{display:inline}

  .tiempo img{width:60px;}

  @media (min-width:1200px) {
     .tiempo img{width:80px;}
  }

  @media (min-width:991px) and (max-width:1200px){
    #navbarNavDropdown ul li{padding-left:2px;padding-right:2px}
  }


/* --- seccion y lateral ---*/
  .row.seccion { background: #fff; }
  .seccion h2 a:hover,.seccion h3 a:hover,.seccion h4 a:hover{text-decoration:none;color:#a9a9a9}
  .seccion .publicidad{min-width:300px;margin: 0 auto;margin-top: 10px;margin-bottom: 10px;}
  .lateral .publicidad{margin-top: 10px;margin-bottom: 10px;}
  .lateral{font-size:15px;line-height: 1.0em;}
  .lateral .opciones label{ line-height: 1.7em; }

  .lateral #cesta { border: 1px #fad218 solid; padding: 5px; border-radius: 3px; background: #fcde1a; position: relative; }
  .lateral #cesta .card-header { background: #FCDE1A;text-transform: uppercase; font-size: 1.2em; font-weight: bold; text-align: center;border-bottom: 0px;margin: 0;  }
  .lateral #cesta .card-header strong { color: #cd0000; }
  .lateral #cesta .contenido { z-index:999999;display: none; position: absolute; border: 2px solid #ffc107; border-radius: 5px; background:#fff; left: -68px; top: 0px; width: 350px; box-shadow: 6px 6px 8px #666; }

  .lateral #cesta .contenido .card .table  {
    padding: 0px; margin:0px;
    border:0px;
  }

  .lateral #cesta .contenido .card-header {

  }

  .lateral #cesta .contenido .card {
    margin:0px;
  }


  .lateral #cesta .contenido .card-footer {
    padding:5px;
    border:0px;
  }

  @media (min-width:1200px) {
    .lateral #cesta .contenido { 
      left:-2px;
    }
  }

  @media (min-width: 768px) and (max-width:992px){
    .lateral #cesta .contenido {
      top: 0px;
      left: -20px;
      margin: 0 auto;
    }
  }

  @media (min-width:570px) and (max-width:768px){
    .lateral #cesta .contenido { 
      top: 0px;  
      left:160px;
      margin: 0 auto;  
    }
  }

  @media (max-width:570px){
    .lateral #cesta .contenido { 
      right: 0%;
      left: 0%;
      width: 100%;
      margin: 0 auto;  
    }
  }


  .lateral #cesta .contenido h4 { margin: 0; background: #FCDE1A; }
  .lateral #cesta .contenido ul { padding: 0 10px; list-style:none;}
  .lateral #cesta .contenido ul li { margin-bottom: 5px; padding-bottom: 5px; border-bottom: 2px solid #CD0000;  }
  .lateral #cesta .contenido .col { display: inline-block; vertical-align: top; }
  .lateral #cesta .contenido .col.imagen { width: 70px; }
  .lateral #cesta .contenido .col.imagen img { border: 1px solid #ddd; padding: 1px; } 
  .lateral #cesta .contenido .col.descripcion { width: 224px; }
  .lateral #cesta .contenido .col.descripcion h5 { margin:0; color: #cd0000; font-size: 1.3em; }
  .lateral #cesta .contenido .col.actions { width: 30px; }
  .lateral #cesta .contenido .total, .lateral #cesta .contenido .btn_compra { text-align: right; margin-right: 10px; margin-bottom: 10px; }
  .lateral #cesta .contenido .total { font-size: 1.3em; font-weight: bold; }
  .lateral #cesta .contenido .total strong { text-transform: uppercase; }
  .lateral #cesta .contenido .total span { color: #cd0000; }
  .lateral #cesta .resumen { margin-top: 5px; background: #f2fdef; background-image: linear-gradient(top, #f2fdef 0%, #ddd 99%); padding: 10px; border-radius: 3px; font-weight: bold; }
  .lateral #cesta .resumen strong { color: #cd0000; }

/*--- header ---*/
.header { background-color:#E30916;padding: 5px;padding-left: 15px; color:white; margin:0;}
.lateral .header {text-align: left;font-size: 18px;font-weight: 900;}
.header-seccion {text-align: left;font-size: 23px;font-weight: 900;}
  
  h2.header{color:white;font-weight:700;margin-bottom:.25rem;font-size:22px;}
  h2.header>a{color:white;}
  h2.header>a:hover{color:#ff0;}
  /*destacados*/
  /* Destacados comuns */
  .destacado .thumb { float: left; margin-right: 5px; width: 123px; position: relative; }
  .destacado .thumb img.imagen { padding: 1px; border: 1px solid #ccc; }
  .estado_reserva { position: absolute; top: 0px; left: 0px; z-index:9999; }
  .destacado .zona { position: absolute; right: 10px; top: 10px;}

  /*---portada noticia---*/
  .portada_noticias_cuerpo  .card , .entradas li + li .card, #entradas .card {
    border-radius: 0px;
    border-bottom: 2px solid red;
  }

.card.noticia { border-bottom: 5px solid #6c757d; }

  /*---destacados---*/
  div.destacados .header h2 { margin: 0; }

  div.destacados.portada div.footer { height: 5px; clear: both; font-size: 0px; }
  div.destacados.portada div.beneficio { font-weight: bold; margin-top: 1.5em; }
  div.destacados.portada div.beneficio span.Dos_por_uno { background: #E30916; color:white; }
  div.destacados.portada div.beneficio span { display:block; padding: 2px 8px; font-size: 1em; width: 100px; }
  div.destacados.portada div.beneficio span.Descuento { background: black; color:white; }
  div.destacados.portada div.container {border-color: #7b7b7a;border-style: solid;border-width: 0 1px 4px 1px;margin-right: 4px;padding-top: 5px;}

  ul.destacados {list-style: none; display: flex; flex-wrap: wrap;}
  div.portada .destacados .destacado { padding: 0 2%;border-right: 1px dotted silver;width: 33.3%;box-sizing: border-box;}
  div.portada .promo .destacados .destacado {width: 100%;}
  div.portada .destacados .destacado:last-child { border-right: none; }

  div.portada .destacados .thumb { position: relative; }
  div.portada .destacados .thumb img.imagen { border: 1px solid #ccc; padding: 1px; }
  div.portada .destacados div.estado_reserva { position: absolute; top: 0px; left: 0px; z-index:9999; }
  div.portada .destacados div.cutter { min-height: 175px; overflow: hidden; }
  
  .item { /* child elements */
      display: inline-block;
      /*margin: 0 0 1em;*/
      width: 100%;
      /*border-bottom:1px solid red;*/
  }
  
  
  /* Destacados tienda */
  .destacados .contenido .title a, .destacados .contenido h3{ font-weight: 700; }
  
  /* Archivo */
  .btn.kym { background: #e6e6e6 url(/images/l/kioskoymas.svg) no-repeat center center/50%; padding: 1rem; font-size:0;}
  /*pagination*/
  .pagination { margin-bottom: 10px;margin-top: 10px;color:black;}
  .pagination a {color:black;}
  .pagination .page-item{border:1px color red;}
  .pagination li a, .pagination li span{min-height: 39px;}
  .pagination li a:hover { background-color: red; color:white;}
  .pagination .active span{ background-color: red!important;color:black;font-weight: bold; }
  .page-item.active .page-link {border:1px solid red;color:black;}
  .pagination .prev a, .pagination .next a{ background-color: tomato; }

#suscribete h2{font-weight:700;font-size:25px}
#suscripcion_nacimiento{width:140px}
#suscribete .botones .btn{margin: 10px;}
#suscribete label>strong>a { color: #e60005; }

  div[itemprop=articleBody]>p.letra1 { font-size: 0.7em; }
  div[itemprop=articleBody]>p.letra2 { font-size: 0.9em; }
  div[itemprop=articleBody]>p.letra3 { font-size: 1em; }
  div[itemprop=articleBody]>p.letra4 { font-size: 1.3em; }
  div[itemprop=articleBody]>p.letra5 { font-size: 1.6em; }

/*asociados*/
#listaAsociados table td{font-size:14px;padding:5px;}
#listaAsociados .lista h6{background-color:grey;margin:0px;padding:5px;color:white;}


/** REPORTAJES **/
.entradas h1 a{margin-left:10px}
.card .preheader{ font-size: 14px; }

/** TIENDA **/
  .proceso_compra { /*padding: 10px;*/ font-size: 13px;min-width: 300px; }

  .proceso_compra .card {
    border:0px solid lightgrey;
    padding: 0px;
  }

  .proceso_compra .card .direccion {
    border:0px;
  }
  .proceso_compra .table td{ border:1px solid lightgrey; }
  .proceso_compra .table tr td:last-child:before{border:0px;}

  .proceso_compra .checkoutStatus { margin: 0 0 10px; padding: 0; }
  .proceso_compra .checkoutStatus li { float: left; list-style: decimal inside; font-weight: bold; font-size: 12px; width: 170px; background: transparent url("../images/l/bak_process_arrow_r_on.png") no-repeat top right; float: left;  height: 40px; list-style: none outside none; padding-right: 9px; }
  .proceso_compra .checkoutStatus li span, .proceso_compra .checkoutStatus li a { background: transparent no-repeat top left; color: #707070; display: block; padding: 8px 6px 15px 18px; text-decoration: none; }
  .proceso_compra .checkoutStatus li a { background-image: url("../images/l/bak_process_arrow_l_on.png"); }
  .proceso_compra .checkoutStatus li.inactive { background-image: url("../images/l/bak_process_arrow_r.png"); }
  .proceso_compra .checkoutStatus li.inactive span { background-image: url("../images/l/bak_process_arrow_l.png"); }
  .proceso_compra .checkoutStatus li.selected { background-image: url("../images/l/bak_process_arrow_r_red.png"); }
  .proceso_compra .checkoutStatus li.selected span { background-image: url("../images/l/bak_process_arrow_l_red.png"); color: #fff; }
  .proceso_compra .checkoutStatus li strong { background: url("../images/l/bullet.png") no-repeat scroll 0 0 transparent; display: inline-block; height: 12px; padding: 7px 9px; width: 9px; }
  .proceso_compra .card.datos td { font-size: 15px;font-weight: normal; }
  .proceso_compra .card.datos td.precio , .proceso_compra .card.datos td.subtotal{ font-size: 13px;padding:3px;font-weight: bold;}
  .proceso_compra .card.datos td.precio_head { font-size: 14px;padding:3px;font-weight:bold;}

  .proceso_compra  .unidades input{
    max-width:30px;font-size: 15px;font-weight: bold;
  }



  /*tabla tamaño movil*/
  @media (max-width:992px){
    .proceso_compra .card.datos td { font-size: 12px;font-weight: normal;padding:3px; }
    .proceso_compra .card.datos td.precio_head { font-size: 11px;padding:3px;font-weight:bold;}
    .proceso_compra  .unidades { padding:1px; }
    .proceso_compra  .unidades input{
      max-width:30px;font-size: 14px;font-weight: bold;
    }
  }

  .proceso_compra h4 { color: #fff; background: #E30916; margin: 0; padding: .3em .75em; font-size: 1.4em; margin-top: 0.25em; }
  .proceso_compra .bloque { background: #eee; padding: 10px 15px; }
  .proceso_compra h5 { color: #cd0000; margin: 0; /*text-transform: uppercase; border-bottom: 1px dotted #ddd;*/ margin-bottom: .5em;font-size: 15px;font-weight: bold; }
  .proceso_compra label { display: block; width: 100%; font-weight: bold; }
  .proceso_compra .row.error input[type=text] { border: dashed #cd0000 1px; background: #fee;}
  .proceso_compra .entrega.form { margin-top: 1em; }
  .proceso_compra.suscriptor.envio .direccion { display: inline-block; vertical-align: top; }
  .proceso_compra.suscriptor.envio .direccion_facturacion { margin-top: 1em; }
  .proceso_compra.suscriptor.envio .direcciones_entrega { margin-top: 1em; }
  .proceso_compra.suscriptor.envio .direccion_otra { margin-top: 1em; }
  .proceso_compra.suscriptor.envio .direccion_otra .entrega { display: inline-block; vertical-align: top; }
  .proceso_compra.pago .pedido .cabecera { margin-bottom: 1em; }
  .proceso_compra .to_check_out  { height:100%;}
  .proceso_compra .to_check_out a { margin: 4px 2px 2px 2px; /*text-align: right;*/font-weight: bold; }
  .proceso_compra .to_check_out .button { margin-right: 3em; }
  .proceso_compra .legal { direction: rtl; margin-bottom: 1em; }
  .proceso_compra .visa { text-align: center; margin-top: 1em; }
  .proceso_compra .buttons .pagar { font-size: 1.5em; }


  /* Signin box desde el proceso de compra*/
  .proceso_compra .suscrip>div { margin-bottom: .5em; }
  .proceso_compra .signin { border: 1px #eee solid; padding: 10px; font-size: 13px; }
  .proceso_compra .signin .required { color: #cd0000; }
  .proceso_compra .signin label .required { display:inline-block; margin-right: .5em; }

  .proceso_compra .lector {  border: 1px #eee solid; /*max-width: 400px;*/ padding: 10px; }
  .proceso_compra .lector .row { margin-bottom: .25em; }
  .proceso_compra .lector #lector_nombre, .proceso_compra.compra .lector #lector_direccion { width: 100%; }
  .proceso_compra .lector #lector_poblacion { width: 80%; }
  .proceso_compra .lector #lector_codigo_postal { width: 30%; }

  .proceso_compra .lector .bloque_direccion { min-height: 150px;}
  .proceso_compra .lector.confirmacion {
    border: 1px dashed #AAAAAA;
    display: inline-block;
    min-height: 125px;
    padding: 15px;
    vertical-align: top;
    
    margin-right: 1em;
  }

  .proceso_compra .bloque_direccion { padding:10px; }
 

  .tienda div.destacados .header h2 { background: yellow; }
  .tienda .destacados .contenido .title {
    margin: 5px 0px 5px 0px;
  }

  .tienda .destacados .precios .alert{
    font-size: 18px;
    padding: 6px;
    line-height: 1em;
  }
  .tienda .destacado { min-width: 250px; box-sizing: border-box; }
  .tienda .destacado .imagen {  max-width: 100%; }
  .tienda .destacado .thumb {  float:none; }
  
  .card-footer.precios{ padding: 5px; }
  .cabecera > .card { border:1px solid lightgrey; }
  .cabecera > .card div{ padding-left:10px; }

  /*cesta*/
  .card.mi_cesta {
    border:0px;
    padding: 0px;
  }

  .mi_cesta .cesta_menu{
    justify-content: space-between;
    flex-direction: column;
    display: flex;
  }

  .cesta_menu .cesta_cuerpo{ background: #FCDE1A; } 
  .cesta_menu .cesta_btns{
    background: #FCDE1A;
    justify-content: center;
    display: flex;
    
  }

  /*textos cesta de la compra*/
  .card.datos thead td {
    font-weight:800;
    font-size: 12px;
  }

  .card.datos td {
    font-weight:500;
    font-size: 11px;
  }

  @media (max-width:992px){
    /*se muestra barra de scroll si es necesario*/
    .pedido .table {
      display:block!important;
      overflow: auto;
    }
  }

  .datos{
    margin-top: 15px;
  }

/***FOOTER***/
  .fa,.footer-bottom ul,.footer-bottom ul li,.footer-middle,.footer-top .row {text-align:center}
  footer{color:#000;font-size:15px;background:#fff;}
  footer h6{color:red;font-weight:700}
  footer a{font-size:13px;}
  .mainfooter .row a{color:#000}
  .footer-top>div a{display:block;width:97px;height:34px;background: url("../images/l/logos.png") #eaeaea;text-indent:100%;overflow:hidden;white-space:nowrap;margin:0 auto;}
  .footer-top .row a.uh:hover{background-position:0 -34px}
  .footer-top  a.uhi{background-position:-97px 0}
  .footer-top  a.uhi:hover{background-position:-97px -34px}
  .footer-top  a.uhm{background-position:-194px 0}
  .footer-top  a.uhm:hover{background-position:-194px -34px}
  .footer-top a.db{background-position:-291px 0}
  .footer-top  a.db:hover{background-position:-291px -34px}
  .footer-top  a.mm{background-position:-388px 0}
  .footer-top a.mm:hover{background-position:-388px -34px}
  .footer-top  a.mdb{background-position:-485px 0}
  .footer-top  a.mdb:hover{background-position:-485px -34px}
  .footer-top  a.gs{background-position:-582px 0}
  .footer-top  a.gs:hover{background-position:-582px -34px}
  footer .card{padding:0}
  .footer-middle{background-color:#fff;padding-top:2em;color:#000}
  .col-subnav a,.subnavigation .nav-link{color:#fff;font-weight:700}
  .footer-bottom{background:#fff;padding-bottom:2em;font-size:17px;}
  .footer-bottom ul li{display:inline}
  .footer-bottom ul li a{text-decoration: underline;font-weight: bold;}
  .footer-top{background:#eaeaea}

/*---CAROUSEL - SWIPER --*/
  /*carousel*/
  .swiper {
    width:100%;
    height:auto;
  }


  @media(max-width:528px){
    .swiper-slide {
      width:auto;
      height:300px!important;
    }
  }


  @media(min-width:528px){
    .swiper-slide {
      width:auto;
      height:400px!important;
    }
  }

  @media(min-width:768px) and (max-width:992px){
    .swiper-slide {
      height:250px!important;
    }
  } 


  .swiper img {
    width:100%;
    height:100%;
  }

  .swiper-slide > img {
    object-fit: contain!important;
    /*transform: translate(-50%, -50%);*/
  }
  /**/

.swiper-button-next, .swiper-rtl .swiper-button-prev, .swiper-button-prev, .swiper-rtl .swiper-button-next { 
  color: #cd0000!important;
}
.swiper .fa-angle-left , .swiper .fa-angle-right {
  color:red;
  text-shadow: -2px 0 rgb(139, 1, 1), 0 2px rgb(156, 0, 0), 2px 0 rgb(139, 1, 1), 0 -2px rgb(139, 1, 1);
}

.swiper-slide { text-align: center; }

/* Publicidad */
div.publicidad { margin-top:0px;margin-left:0px;font-size:11px;}

div#interes { background: white; }
div.fin { clear: both; }
div.puntitos { background: url(/images/puntitos.gif) repeat-x center; height: 2px;}

/*Estilos traido de club.css*/
img {border:none;margin:0px; }
.club-grande { background-color: #E4E5E7; border: 1px solid #DFDFDF;}
.desplegableHome { font-size: 9px; color: #000; text-decoration: none;}
.textgris { font-size: 11px; color: #333; text-decoration: none;}
a.textgris:hover { font-size: 11px; color: #FF7300; text-decoration: none;}
.textgrisM { font-size: 14px; color: #838383; text-decoration: none;}
a.textgrisM:hover { font-size: 14px; color: #FF7300; text-decoration: none;}
.textgrisG { font-size: 18px; color: #838383; text-decoration: none;}
a.textgrisG:hover { font-size: 18px; color: #FF7300; text-decoration: none;}
.textgrisNegreta { font-size: 11px; color: #666; font-weight: bold; }
.textgris9 { font-size: 9px; color: #666; }
.caixablanca { background-color: #FFFFFF; border: 1px solid #FF0000;}
.caixabordevermell { border: 1px solid #FF7300; background-color: #FFF;}
.textblanc25 { font-size: 22px; color: #FFF; font-weight: bold;}
.titolofertesnegres { font-size: 22px; color: #000; font-weight: bold;}

.ver_detalles { text-align: right; }
.ver_detalles a {  padding: 5px 5px 5px 5px;margin:2px; }
a.elipsis { color: red; font-weight: bold; }
a.elipsis:hover {color: gray; }

.textnegredepreunormalM { font-size: 12px; color: #000; text-decoration: none;}
.textnegredepreunormalG { font-size: 16px; color: #000; text-decoration: none;}

.titulo { color: #E30916; }
h3.titulo { font-size: 15px; color: #E30916; font-weight: bold; line-height: 1.1em; margin:0; font-weight: bold;}
.nocols h3.titulo { font-size: 20px; }
h3.titulo a { text-decoration: none; color: #E30916; }
h3.titulo a:hover { text-decoration: underline; }
/*.nocols h4.subtitulo { font-size: 15px; font-weight: bold; }*/

.titolofertesnegrestamany2:hover { font-size: 16px; color: #000; font-weight: bold; text-decoration: none;}
.caixabordevermellexcesq { background-color: #FFF; border:1px solid #FF0000;}
.textblanc15 { font-size: 12px; color: #FFF; font-weight: bold;}
.textblanc12 { font-size: 12px; color: #FFF;}
.textblanc12-2 { font-size: 10px; color: #FFF; font-weight: normal; text-decoration: none;}
a.textblanc12-2:hover { font-size: 10px; color: #EEE; font-weight: normal; text-decoration: none;}
.textnegre { font-size: 10px; color: #000; text-decoration: none;}
.negre { color: #000;}
.bordeiframe { border: 1px solid #FFF;}
.botomes { border: 1px solid #FF0000; background-color: #EFEFEF; font-size: 10px; color: #666; text-decoration: none;}
.textblanctitolforum { font-size: 11px; color: #FFF; font-weight: normal; }
.caixabordegris { border: 1px solid #CCC; background-color: #FFF;}
.subtitolocio { font-size: 12px; color: #00517B; font-weight: normal; text-decoration: none;}
.subtitolpromo { font-size: 12px; color: #E30916; font-weight: normal; text-decoration: none;}

.titolnoticas { font-size: 15px; color: #000; font-weight: bold; text-decoration: none;}
.textnegre-calendari { font-size: 12px; color: #000; text-decoration: none; font-weight: bold;}
.textrojo-calendari-dias { font-size: 12px; color: #FF0000; text-decoration: underline; font-weight: bold;}
.textgris-calendari { font-size: 12px; color: #666; text-decoration: none; font-weight: bold;}

.ventaja { color: red; font-weight: bold;}
div.errores {margin: 10px;}
div.errores h3 { color: red; margin: 0px;}

.info_suscriptor>.card-body { background: url("/images/cab_info.png") right bottom no-repeat; }
.card.cierre { border-bottom: 5px solid #E30916; }
.conocenos_mejor ul>li { color: red; list-style: disc inside;}

.bubbleInfo{ position: relative; }
.popup { display: none; position: absolute; width: 236px; z-index: 50;}
.hint { display: block; float:right; width: 200px; border: 1px solid #c93; padding: 10px 12px; background-color: #ffc; z-index: 50;}
.hint-pointer { position: relative; left: 1px; display: block; float:right; top: 5px; width: 10px; height: 19px; background: url(/images/tooltip-pointer.gif) left top no-repeat;}

#franjaFecha { border-bottom: 2px solid #e60005; font-size: 13px; }

div#rss_list img { margin-top: 4px; }

div#google_results { margin: 10px;}
div#google_results input {border: 1px solid #333 }

div#bottompager { padding-top: 0.5em;}
.post_formulario>.form-box>form>.text-right { margin-right: 1em; }
.post_formulario>h3 { display: none; }
#sf_comment_list>.comment{border-bottom:1px dotted gray;} 
#sf_comment_list div.pager div.indicador {/*float: left;*/ font-size: 0.85em; line-height: 2em; margin-left: 10px;}

#sf_comment_list .autor { font-size:0.8em; color: #958D7A;}
#sf_comment_list .text { font-size: 1em; }

#sf_comment_ajax_indicator { width: 20px; height: 20px; margin: 0; padding: 0; float: left; }
label[for=sf_comment_conditions]  { width: 100%; }

div.pager div.indicador {float: right; margin-right: 20px;}

/*Tooltip aceptar politica de comentarios en post noticias*/
.tooltip {display:none; width: 208px; left: 9em !important; top: 2em !important; z-index: 1000;}
.tooltip a {color:#ad4;font-size:11px;font-weight:bold;}
#document .tooltip p {margin:0!important; padding:0.1em 0.3em!important; color: #333!important; font-size: 0.9em; line-height: 1.25em;}
.tooltip h6 {margin:0; font-size: 1.1em; color: #fff;}

.post_formulario .accept_check strong { color: #A60000;}

.post_formulario h2 { color:white;background: rgb(136, 135, 135); padding-left: 5px; font-size: 1.15em;margin: 10px 10px 0px 10px;}
#comentarios .header { clear: left;}

div#footer div.puntitos { margin: 0 40px;}

input.cantidad {  text-align: right; }

/* Boton amarillo */
.button, input.comprar[type=submit] { border: 1px solid #f9c15a; color: #333;  display: inline-block; margin:2px;padding: 4px 10px; font-size: 12px; font-weight: bold; background-image: url(../images/boton_comprar.png) no-repeat left center linear-gradient(top, #ffe329 0%, #ffc700 99%); background-image: -moz-linear-gradient(top, #ffe329 0%, #ffc700 99%); background-image: -webkit-linear-gradient(top, #ffe329 0%, #ffc700 99%); border-radius: 6px; -webkit-border-radius: 6px; }

/* Boton gris oscuro */
.button.seguir { border: 1px solid #777; 
/* Falta ajustar estos gradientes */ 
background-image: -moz-linear-gradient(top, #eee 0%, #666 99%);
background-image: -webkit-linear-gradient(top, #eee 0%, #666 99%);
}

/* Boton gris claro */
.button.gris { border: 1px solid #eee;
background-image: -moz-linear-gradient(top, #eee 0%, #aaa 99%);
background-image: -webkit-linear-gradient(top, #eee 0%, #aaa 99%);
}

.buy_link { text-align: right; /*margin-bottom: .75em;*/ }
.destacado .buy_link { text-align: center; }

/*.button span , input[type=submit] span { display: inline-block; padding: 2px 0 2px 22px; text-align: center; }
.button.comprar span , input.comprar[type=submit] span { background: url("/images/boton_comprar.png") no-repeat scroll left top transparent; }*/
.button.comprar:hover span { background-position: 0 -29px; color: #cd0000; }
.button.usuario span { /*background: url("/images/iconos/user.png") no-repeat scroll left center transparent;*/ }



table.cesta { font-size: 11px; width: 100%; }
@media(max-width:992px){
	.proceso_compra .table 
	{ 
		font-size: 13px; width: 100%;
		display: block;overflow: auto;
		border:1px solid lightgrey; 
	}
}
table.cesta thead { font-weight: bold; background: #eee; }
table.cesta thead td.precio { text-align: center; }
table.cesta thead td.unidades { vertical-align: bottom; }
table.cesta td { padding: 5px; border: none; vertical-align: bottom; border: 1px dotted #eee; }
table.cesta td.promocional { border: none; }
table.cesta td h5 { margin: 5px 0 2px; font-weight: bold; font-size: 1.1em; }
table.cesta td h5 a { color: #cd0000; }

.cesta ul { width: 100%; }
.cesta ul li .col { float: left; }
.cesta .imagen { width: 155px; float: left; }
.cesta .imagen img {border: 1px solid #ccc; padding: 1px; }
.cesta .precio { width: 80px; text-align: right; }
.cesta .unidades { width: 80px; text-align: right; margin-right: 5px; }
.cesta .subtotal { width: 80px; text-align: right; margin-right: 5px; }

.resumen { margin-top: 1em; font-size: 15px; }
.resumen .promocional { float: left; width: 300px; }
.resumen .totalizar { 
	 background: #eee; 
	 padding: 20px; font-weight: bold; }
.resumen .totalizar div { text-align: right; }
.resumen .totalizar div span.label { display: inline-block;  }
.resumen .totalizar div span.importe { display: inline-block; width: 100px; }

.resumen .totalizar .total { color: #cd0000; font-size: 1.3em; margin-top: .5em; border-top: 1px solid #ddd;}




.box {  /*border: 1px #eee solid; padding: 10px;*/ }

div.message { background: url("/sf/sf_admin/images/ok.png") no-repeat scroll 5px 2px #73B65A; color: #FFFFFF; font-size: 15px; margin: 5px; padding: 5px 20px 5px 25px; border: 1px solid #070; border-radius: 4px; }
div.message.error { background: url("/sf/sf_admin/images/cancel.png") no-repeat scroll 5px 2px #f99; border: 1px solid #cd0000;}

.recuerda_usuario{padding:20px;border:0 solid #000}
.recuerda_usuario .row { margin-top: 5px; }
.recuerda_usuario label { display: block; font-weight: bold; }
.recuerda_usuario img{display:block;max-width:100%;height:auto}

.suscriptor.box { margin: 1em;  padding: 1em; border:0px solid #ccc; border-radius: 10px; }
.suscriptor .bloque { font-size: 11px; }

.suscriptor .signin { margin: 1em; border:1px solid #ccc; border-radius: 5px; }
.suscriptor .signin h5 { color: #cd0000; background: #ccc; margin: 0; padding: .3em .5em; font-size: 1.1em;}
.suscriptor .signin p, .suscriptor .signin form { margin: 1em; } 
/*url("/sf/sf_admin/images/cancel.png") no-repeat scroll 5px 2px #f99*/
.suscriptor .signin .row { margin-top: .25em; }
.suscriptor .signin label { display: block; font-weight: bold; }

.suscriptor .signin .email { width: 300px; display: inline-block; }
.suscriptor .signin .password { width: 250px; display: inline-block; }
.suscriptor .signin .recover { padding:10px 25px;background-color: #f99;border-radius: 4px; }
.suscriptor .signin .recover a { color:black;text-decoration: underline;}

label.check { display: inline; }

.entrega form { width: 80%; border: 1px solid #ccc; padding: 1em; }

/* Antiguo ocio.css */
.imagery { position: relative; margin-top: 1em; }
div#galeria div.header { display:none; }
div#galeria div.bigFotoHolder { overflow: hidden; text-align: center; border: 1px solid #ccc; padding: 1px; }
div#galeria div.bigFotoHolder>img { display: inline; max-width: 100%; }

div.barra { margin-top: 1em; background: #eee; padding: 10px; }
.barra>div>a { margin: 3px;}
div.limite_reserva { color: black; font-size: 1.3em; font-weight: bold; }

div.beneficio .default_tipo { background: #ddd;  color: #E30916; }
div.beneficio .Descuento{ background: black;  color:white; }
div.beneficio .Dos_por_uno { background: #E30916; color:white; }
div.reserva { margin-top: 15px; }

.promocion h5 { margin: 0; color:#cd0000; }

#sendmail { display: none; width: 600px; }
.txt {
	display: inline-block;
	color: #676767;
	width: 420px;
	font-family: Arial, Tahoma, sans-serif;
	margin-bottom: 10px;
	border: 1px dotted #ccc;
	padding: 5px 9px;
	font-size: 1.2em;
	line-height: 1.4em;
}

textarea{resize: none;}

.txtarea {
	display: block;
	resize: none;
	color: #676767;
	font-family: Arial, Tahoma, sans-serif;
	margin-bottom: 10px;
	width: 500px;
	height: 150px;
	border: 1px dotted #ccc;
	padding: 5px 9px;
	font-size: 1.2em;
	line-height: 1.4em;
}

.txt:focus,
.txtarea:focus {
	border-style: solid;
	border-color: #bababa;
	color: #444;
}

input.error,
textarea.error {
	border-color: #973d3d;
	border-style: solid;
	background: #f0bebe;
	color: #a35959;
}

input.error:focus,
textarea.error:focus {
	border-color: #973d3d;
	color: #a35959;
}
/*
#send {
	color: #dee5f0;
	display: block;
	cursor: pointer;
	padding: 5px 11px;
	font-size: 1.2em;
	border: solid 1px #224983;
	border-radius: 5px;
	background: #1e4c99;
	background: -webkit-gradient(linear, left top, left bottom, from(#2f52b7), to(#0e3a7d));
	background: -moz-linear-gradient(top, #2f52b7, #0e3a7d);
	background: -webkit-linear-gradient(top, #2f52b7, #0e3a7d);
	background: -o-linear-gradient(top, #2f52b7, #0e3a7d);
	background: -ms-linear-gradient(top, #2f52b7, #0e3a7d);
	background: linear-gradient(top, #2f52b7, #0e3a7d);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2f52b7', endColorstr='#0e3a7d');
}

#send:hover {
	background: #183d80;
	background: -webkit-gradient(linear, left top, left bottom, from(#284f9d), to(#0c2b6b));
	background: -moz-linear-gradient(top,  #284f9d, #0c2b6b);
	background: -webkit-linear-gradient(top, #284f9d, #0c2b6b);
	background: -o-linear-gradient(top, #284f9d, #0c2b6b);
	background: -ms-linear-gradient(top, #284f9d, #0c2b6b);
	background: linear-gradient(top, #284f9d, #0c2b6b);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#284f9d', endColorstr='#0c2b6b');
}

#send:active {
	color: #8c9dc0;
	background: -webkit-gradient(linear, left top, left bottom, from(#0e387d), to(#2f55b7));
	background: -moz-linear-gradient(top,  #0e387d,  #2f55b7);
	background: -webkit-linear-gradient(top, #0e387d, #2f55b7);
	background: -o-linear-gradient(top, #0e387d, #2f55b7);
	background: -ms-linear-gradient(top, #0e387d, #2f55b7);
	background: linear-gradient(top, #0e387d, #2f55b7);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0e387d', endColorstr='#2f55b7');
}
*/

/*.bloque .card, .entradas .card{margin-top:10px}*/


@media (max-width:768px){
  /*.seccion .publicidad{margin:0 auto}
  .publicidad_banner .publicidad{margin-top:10px}*/
}



.card .entrada .comentarios{font-weight:700;margin-top: 5px;}
.card .entrada .comentarios li{margin: 0px;}
article #title {font-size: 25px;}
.entrada .footer div.tags{float:left;font-weight:700}
.entrada .footer.clearfix{ font-size: 15px; }
.entrada .archivado { font-size: 15px; }

.detalles a:hover{padding:5px}

.promo .btn{margin-right:-10px}
.card .alert.alert-dark.col {margin-left: 1px;margin-right:1px;}

.unidades{ max-width:80px; } 

.tienda .destacados .precios .alert {
  font-size: 19px;
}



.card .destacado.clearfix {
  border-bottom: 2px solid red;
}
.card.destacado .pie .btn{ margin: 1px; }


.card .colInfo h2,.entradas h1 a,.head_promo h1{text-align:left}
.head_promo{background-color:#E30916}
.head_promo a{text-align:center;height:2.2em;line-height:1.1em}
#suscribibete .header{margin-top:17px}

#tCalendario td,#tCalendario th,#tCalendario thead tr{padding:3px}
td,th,thead tr{padding:5px}
.publicacion_fecha{font-size:12px}

.titolocio{text-align:left;font-size:28px}
.titolocio img{width:auto;height:auto}

[id^=bloque_] h4{text-align:left;font-size:24px;color:grey}


.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active, a.ui-button:active, .ui-button:active, .ui-button.ui-state-active:hover {
  border: 1px solid var(--brand-color);
  background: var(--brand-color);
  font-weight: normal;
  color: #fff;
}
.card .post_formulario { border:1px; }
.post_formulario h2{ padding: 6px 6px 6px 10px;}
.post_formulario input[type=text]{width:100%!important}
.post_formulario label { font-weight: bold; }
.post_formulario .form_error { color: red; }

.suscriptor h3{font-size:17px}

.pedidos .pedido{margin:0px 0px 10px 0px ;font-size:15px}
.pedidos .pedido .pedido-num{color:#00f;font-weight:700}
.pedidos .pedido .pedido-eur,.pedidos .pedido .pedido-fecha{color:#000;font-weight:700}
.estado-pedido-error{color:red;font-weight:700}
.estado-pedido-ok{color:green;font-weight:700}

/*Encuesta*/
div.encuesta .resultado>.chart{height:200px;}

div.resumVotes div.vote{margin-bottom:5px}
div.resumVotes div.vote span.respuesta{font-weight:700}
div.resumVotes div.vote div.cien_por_cien{height:10px;width:100%;font-size:0;border:1px solid #000; border-radius: 3px;}
div.resumVotes div.vote div.caja{height:100%;font-size:0;border-right:1px solid #000}
div.resumVotes div.countVotes{text-align:right;margin-right:20px}

#datos_envio label, #datosPersonales label {
  margin-bottom: 0px!important;
  margin-top: 0.2rem!important;
  font-weight: bold;
}

#cuestionario textarea {
  max-width: auto;
  max-height: 200px;
}

.condiciones .custom-checkbox{ margin-bottom: 10px; }

.subnavigation-container{background:#3d6277;text-align:center}
.subnavigation-container .navbar{display:inline-block;margin-bottom:-6px}
.navbar li a { line-height: 1;}
@media (max-width:992px){
  .navbar li a { line-height: 2;}
}
.col-subnav a{padding:1rem}
.col-subnav .active{border-top:5px solid orange;background:#fff;color:#000}

#linea_directa_fc {background:url(/images/cab_fina.png) right bottom no-repeat #fff}
#linea_directa_fc>blockquote{margin-right: 85px!important; }

.archivos a{color:#000}

section.error .sfTMessageWrap{padding:10px;top:15px;left:175px;width:320px;height:200px}
section.error .sfTMessageWrap h4{font-size:14px}

.foto .fas{padding:5px;font-size:20px;width:50px;}

@media (max-width:992px){
  .fas{
    /*padding:20px 5px 0px 0px;
    font-size:15px;
    width: 20px;*/
  }

  .table td.foto {
    padding: 0px;
    margin:0px;
  }

  .foto .fas {
    padding: 1px;
    font-size: 20px;
    width: 30px;
  }
  
}


@media (max-width:992px){
  /*excepcion form calidad*/
  #cuestionario .fOpciones .custom-control.custom-radio.custom-control-inline{
    margin-right: 0.5em;
  }
}

.fa-facebook{color:#3B5998;}
.fa-twitter{color:#55ACEE;}
.fa-whatsapp{color:#0dc143;}


@media(max-width:991px){
   .print_btn {
    display: none;
  }
}

/*ocultar botones navegacion entre pag*/
.page-link {
  border:1px solid #ddd;
}

.pager .botones .disabled{
  display: none;
}
.pager .botones span{
  border-radius: 3px;padding:3px 5px;margin: 5px;background-color: #d8d8d8;
}

.sede h2>span { color: red;}
.map-container{ overflow:hidden; padding-bottom:56.25%; position:relative; height:0; }
.map-container iframe{ left:0; top:0; height:100%; width:100%; position:absolute; }

.resumentotal { font-size:20px;border: 1px solid black; background: #E8E8E8; padding: 10px; margin-top: 10px;}
.resumentotal input:disabled { background: #fff;}

div.lista td.nombre>a { color: black; font-weight: bold;}
div.lista table tr.asociado td.beneficio { color: red;  }
div.asociado h3 { background: #E8E8E8; margin:0; }

span.dato{ font-weight: bold; }
span.dato a { color: red }
span.beneficio { color: red; font-weight: bold; }
ul.da, ul.tag-cloud{padding:0;}
ul.da>li{list-style:none;}
ul.da>li:before{ content: "» "; }

.tags-list li { display:inline-block; margin:1px; border:0 solid #000; padding:2px 7px; border-radius:4px; background-color:grey}
.tags-list li a { color:#fff; text-align:center;}
.tags-list>li a:hover { color: red;}

.sorteo h4 { background: #ddd; text-align: center; color: #000; padding: .75rem; border-radius:5px;}
.sorteo ul { margin-left: 1.5rem;}
.sorteo ul>li { list-style: disc inside; }

.card.video>.card-body { padding: 0; }
.card.video>.card-body>iframe { max-width: 100%; }
.card.video>.card-body>.responsive { overflow:hidden; padding-bottom:56.25%!important; position:relative; height:0; } 
.card.video>.card-body>.responsive iframe, .facebook-responsive video { left:0; top:0; height:100%; width:100%; position:absolute; }

.punto_venta .heading-info {
    background-color: var(--brand-color);
    color: white;
}
.punto_venta .leaflet-popup .info h5>a {
    color: var(--brand-color);
}

.video-js button.vjs-big-play-button {
    top: calc(50% - .81666em);
    left: calc(50% - 1.5em);
    background-color: #cd0000;
}

.video-js:hover button.vjs-big-play-button {
    filter: brightness(50%);
    background-color: #cd0000;
}

@media print {
	nav , .header,  footer ,.comentarios, .archivado, .sf-minitoolbar, div[id^="sfToolbarMainContent-"], .top_bar , .carousel-indicators , .barra, #horizontal_carousel , .lateral, #franjaFecha, #menu , #search, .goback, .logos, .etiqueta .suscriptor .lugar_entrega h5, #sfWebDebug , .buy_link, div.lateral { 
	    display:none; 
	}

	#textnormal p {
	    break-before: always;
	    box-decoration-break: clone;
	}


	a { text-decoration:none; color: black;}
	h1 { font-size: 1.5em }
	h2 { font-size: 1.4em }
	h3 { font-size: 1.3em }
	h4 { font-size: 1.2em }

	img { max-width: 200px; }

	.card, .card-footer {
	    border: 1px solid black;
	    padding: 20px;
	}

	.masInfo {
	    border-top:1px solid black;
	}
	.precio_lector .precio_cantidad, .precio_suscriptor .precio_cantidad{
	    font-weight: bold;
	}

	.swiper-wrapper {
	    transform: none!important;
	}

	.swiper-slide > img{
	    float:left;
	}

	@page {  margin: 60px }

	body {
	    margin: 0px;
	    padding: 10px;
	    -webkit-print-color-adjust:exact;
	    page-break-before: always;
	}
}
